import api from "@/base/utils/request";

export const getListData = data => {
  return api({
    url: '/admin/portal/comment/index',
    method: 'post',
    data
  })
}

export const getTabsData = data => {
  return api({
    url: '/admin/portal/comment/tabs',
    method: 'post',
    data
  })
}

export const softDelete = data => {
  return api({
    url: '/admin/portal/comment/softDelete',
    method: 'post',
    data
  })
}

export const putBack = data => {
  return api({
    url: '/admin/portal/comment/putBack',
    method: 'post',
    data
  })
}

export const trueDelete = data => {
  return api({
    url: '/admin/portal/comment/del',
    method: 'post',
    data
  })
}

export const updateStatus = data => {
  return api({
    url: '/admin/portal/comment/updateStatus',
    method: 'post',
    data
  })
}

export const updateBlacklist = data => {
  return api({
    url: '/admin/portal/comment/updateBlacklist',
    method: 'post',
    data
  })
}

export const reply = data => {
  return api({
    url: '/admin/portal/comment/reply',
    method: 'post',
    data
  })
}

export const getCommentDetails = data => {
  return api({
    url: '/admin/portal/comment/commentDetails',
    method: 'post',
    data
  })
}

export const setTop = id => {
  return api({
    url: '/admin/portal/comment/top',
    method: 'post',
    data: {
      comment_id: id
    }
  })
}

export const reportDetails = data => {
  return api({
    url: '/admin/portal/comment/reportDetails',
    method: 'post',
    data
  })
}

export const savePraise = data => {
  return api({
    url: '/admin/portal/comment/savePraise',
    method: 'post',
    data
  })
}
