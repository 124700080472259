<template>
  <div class="list-page">
    <list-filter :filterForm="filterForm" @update="doFilter" />
    <list-tabs v-model="filterForm.tab" :isLoading="loading" :tabs="tabArr" @tab-click="handleTabClick">
    </list-tabs>
    <div class="list-content" v-loading="loading">
      <div class="list-btn" v-if="filterForm.tab === 'normal'">
        <el-button type="primary" size="small" @click="toUpdateStatus(0, 2)">通过审核</el-button>
        <el-button type="danger" size="small" @click="toDelete()">删除</el-button>
        <el-button size="small" @click="toUpdateBlacklist(0, 1)">列入黑名单</el-button>
      </div>
      <div class="list-btn" v-if="filterForm.tab === 'blacklist'">
        <el-button type="primary" size="small" @click="toUpdateBlacklist(0, 0)">移出黑名单</el-button>
      </div>
      <div class="list-btn" v-if="filterForm.tab === 'deleted'">
        <el-button type="success" size="small" @click="toPutBack()">还原</el-button>
        <el-button type="danger" size="small" @click="toDelete(0, true)">删除</el-button>
      </div>
      <div class="list-btn" v-if="filterForm.tab === 'unchecked'">
        <el-button type="primary" size="small" @click="toUpdateStatus(0, 2)">通过</el-button>
        <el-button type="danger" size="small" @click="openCheckDialog()">不通过</el-button>
<!--        <el-button type="danger" size="small" @click="toDelete()">删除</el-button>-->
      </div>
      <div class="list-btn" v-if="filterForm.tab === 'checked' || filterForm.tab === 'rejected'">
        <el-button type="danger" size="small" @click="toDelete()">删除</el-button>
      </div>
      <el-table :data="tbody" class="thead-light" stripe style="width: 100%"
        @selection-change="handleSelectionChange" @sort-change="sortChange">
        <!-- 勾选-->
        <el-table-column fixed type="selection" width="55"></el-table-column>
        <!-- 操作 -->
        <el-table-column label="操作" :width="filterForm.tab === 'unchecked' ? 250 : 150" fixed="right">
          <template slot-scope="scope">
            <el-button-group v-if="filterForm.tab === 'blacklist'">
              <el-button size="small" type="text" @click="toUpdateBlacklist(scope.row.id, 0)">移出黑名单
              </el-button>
            </el-button-group>
            <el-button-group v-if="filterForm.tab === 'deleted'">
              <el-button size="small" type="text">详情</el-button>
              <el-button size="small" type="text" @click="toPutBack(scope.row.id)">还原</el-button>
              <el-button size="small" type="text" @click="toDelete(scope.row.id, true)">删除</el-button>
            </el-button-group>
            <!--              <el-button-group v-if="filterForm.tab === 'checked'">-->
            <!--                -->
            <!--              </el-button-group>-->
            <!--              <el-button-group v-if="filterForm.tab === 'unchecked'">-->
            <!--                -->
            <!--              </el-button-group>-->
            <el-button-group v-else>
              <template v-if="filterForm.tab === 'unchecked'">
                <el-button type="text" size="small" @click="toUpdateStatus(scope.row.id, 2)">通过</el-button>
                <el-button type="text" size="small" @click="openCheckDialog(scope.row.id)">不通过</el-button>
              </template>
              <el-button v-if="scope.row.check_status === 2" size="small" type="text" @click="toReply(scope.row.id)">回复</el-button>
              <el-button size="small" type="text" @click="toDetail(scope.row.id)">详情</el-button>
              <el-button size="small" type="text" @click="toDelete(scope.row.id)">删除</el-button>
            </el-button-group>
          </template>
        </el-table-column>
        <template v-for="(th, index) in thead">
          <el-table-column show-overflow-tooltip :prop="th.prop" :label="th.label"
            :min-width="th.min_width || ''" :key="index" :sortable="th.sortable">
            <template slot-scope="scope">
              <!-- 标题 -->
              <span v-if="th.prop === 'article.title'" class="cursor-pointer"
                @click="previewArticle(scope.row.article.hash_id)">{{scope.row.article.title | placeholder}}</span>
              <!-- 置顶 -->
              <el-checkbox v-else-if="th.prop === 'is_top'" :checked="scope.row[th.prop] === 1"
                :disabled="scope.row.ancestor > 0" @change="changeStatus(scope.row, scope.$index, th.prop)">
              </el-checkbox>
              <!-- 审核状态 -->
              <template v-else-if="th.prop === 'check_status'">
                <list-status-text :type="scope.row.check_status ? 'success' : 'warning'" :text="scope.row[th.prop] ? '已审核' : '未审核'" />
              </template>
              <!-- 运营点赞数 -->
              <ListInputPopover class="number-input" :integer="true" v-else-if="th.prop === 'praise_count'"
                v-model="scope.row[th.prop]" @change="updateCount(scope.row)" />
              <!-- 举报数 -->
              <div v-else-if="th.prop === 'complaints_count'">
                {{ scope.row[th.prop] }}
                <el-button v-if="scope.row[th.prop] > 0" type="text" @click="checkReportRecode(scope.row)">查看
                </el-button>
              </div>
              <!-- 普通 -->
              <span v-else>{{ scope.row[th.prop] | placeholder }}</span>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>
    <Pagination :total="pageData.total" :page="pageData.current_page" :change="changPage" />
    <reply-dialog :id="replyDialog.id" v-model="replyDialog.show" @update="getListData"></reply-dialog>
    <detail-dialog v-model="detailDialog.show" :id="detailDialog.id" @preview="previewArticle($event)"
      @update="afterLikeUpdate"></detail-dialog>
    <article-viewer v-model="previewDialog.show" :options="previewDialog.data"></article-viewer>
    <!-- 举报记录弹窗 -->
    <ReportRecodeDialog v-model="showReportRecodeDialog" :requestData="ReportRecodeRequest"/>
    <check-dialog ref="checkDialog" @confirm="handleCheckConfirm" />
  </div>
</template>

<script>
  // 接口
  import {
    getListData,
    putBack,
    savePraise,
    setTop,
    softDelete,
    trueDelete,
    updateBlacklist,
    updateStatus,
  } from "../api/comment-list";
  // 混入
  import init from "../mixins/list-init";
  import filter from "../mixins/list-filter";
  import tabs from "../mixins/list-tabs";
  import list from "../mixins/list-operation";
  import pagination from "../mixins/list-pagination";
  // 组件引入
  import ListFilter from "../components/CommentList/ListFilter";
  import ReplyDialog from "../components/CommentList/ReplyDialog";
  import DetailDialog from "../components/CommentList/DetailDialog";
  import ArticleViewer from "../../../base/components/Preview/ArticleViewer";
  import {getPreviewData} from "../api/base";
  import ListInputPopover from "@/base/components/List/ListInputPopover.vue";
  import ReportRecodeDialog from "@/modules/common/components/ReportRecodeDialog";
  import CheckDialog from "@/modules/article-admin/components/CommentList/CheckDialog";
  import ListStatusText from "@/base/components/List/ListStatusText";

  export default {
  name: "comment-list",
  // 按需混入
  mixins: [init, filter, tabs, list, pagination],
  components: {
    ListStatusText,
    CheckDialog,
    ArticleViewer,
    DetailDialog,
    ReplyDialog,
    ListFilter,
    ListInputPopover,
    ReportRecodeDialog,
  },
  data() {
    return {
      thead: [
        { label: "ID", prop: "id", min_width: 70 },
        { label: "用户", prop: "user_name", min_width: 110 },
        { label: "评论内容", prop: "content", min_width: 200 },
        { label: "文章", prop: "article.title", min_width: 120 },
        {
          label: "评论时间",
          prop: "create_time",
          min_width: 160,
          sortable: false,
        },
        {
          label: "回复数",
          prop: "replies_count",
          min_width: 100,
          sortable: false,
        },
        {
          label: "运营点赞",
          prop: "praise_count",
          min_width: 110,
          sortable: false,
        },
        {
          label: "用户点赞",
          prop: "likes_count",
          min_width: 110,
          sortable: false,
        },
        { label: "举报数", prop: "complaints_count", min_width: 100 },
        { label: "状态", prop: "check_status", min_width: 90 },
        { label: "置顶", prop: "is_top", min_width: 80 },
      ],
      tabArr: [
        {
          name: "normal",
          label: "全部",
        },
        {
          name: "checked",
          label: "已审核",
        },
        {
          name: "unchecked",
          label: "未审核",
          badge: 1
        },
        {
          name: "rejected",
          label: "未通过"
        },
        {
          name: "blacklist",
          label: "黑名单",
        },
        {
          name: "deleted",
          label: "回收站",
        },
      ],
      // 筛选对象
      filterForm: {},
      // 筛选缓存
      filters: {
        normal: null,
        deleted: null,
      },
      pageData: {
        page_size: 15,
        tab: "normal",
      },
      replyDialog: {
        show: false,
        id: -1,
      },
      detailDialog: {
        show: false,
        id: -1,
      },
      previewDialog: {
        show: false,
        data: {},
      },
      showReportRecodeDialog: false, //是否显示举报记录弹窗
      ReportRecodeRequest: {
        page: 1,
        page_size: 15,
        data_type: "comment", // 举报数据类型
        data_table: "portal_comment", // 举报数据表
        data_id: "", // 举报内容id
      },
    };
  },
  created() {
  },
  methods: {
    handleCheckConfirm(e) {
      this.toUpdateStatus(e.id || 0, 1, e.content)
    },
    // 查看举报记录
    checkReportRecode({ id }) {
      this.ReportRecodeRequest.data_id = id;
      this.showReportRecodeDialog = true;
    },
    afterLikeUpdate() {
      this.detailDialog.show = false;
      this.getListData();
    },
    previewArticle(id) {
      this.previewDialog.show = true;
      if (this.previewDialog.data && id !== this.previewDialog.data.id) {
        this.previewDialog.data = {};
        getPreviewData(id)
          .then((res) => {
            this.previewDialog.data = res.data;
          })
          .catch((err) => {});
      }
    },
    toDetail(e) {
      this.detailDialog.id = e;
      this.detailDialog.show = true;
    },
    toReply(e) {
      this.replyDialog.id = e;
      this.replyDialog.show = true;
    },
    /**
     * 由于每个列表的筛选条件不一，这个方法只能在 view 中定义
     * 用于初始化筛选条件，也作切换tab时还原筛选条件
     * @param {String} name   tab名称，不传时表示初始化
     */
    initFilterForm(name) {
      this.filterForm = Object.assign(
        {},
        {
          start_time: -1, // 开始时间
          end_time: -1, // 结束时间
          keyword: "", // 评论内容
          title: "", // 文章标题
          tab: name || "normal", // 当前筛选tab
          page_size: 15, // 每页多少条数据
        }
      );
    },
    /* 请求API，以do开头 */
    /**
     * 更新评论审核状态
     * @param {Array} id       id数组
     * @param {Number} status   0:待审核，1：不通过， 2：通过
     * @param reason
     */
    doUpdateStatus(id, status, reason) {
      return updateStatus({
        comment_id: id,
        check_status: status,
        reason
      });
    },
    /**
     * 软删除
     * @param {Array} id      id数组
     * @returns {Promise}     请求结果
     */
    doSoftDelete(id) {
      return softDelete({
        comment_id: id,
      });
    },
    /**
     * 硬删除
     * @param {Array} id
     * @returns {Promise}
     */
    doTrueDelete(id) {
      return trueDelete({
        comment_id: id,
      });
    },
    /**
     * 还原
     * @param {Array} id
     * @returns {Promise}
     */
    doPutBack(id) {
      return putBack({
        comment_id: id,
      });
    },
    doGetListData(params) {
      return getListData(params);
    },
    /**
     * 黑名单
     * @param {Array} id
     * @param {Number} status   0:取消黑名单 ，1：加入黑名单
     */
    doUpdateBlacklist(id, status) {
      return updateBlacklist({
        comment_id: id,
        is_blacklist: status,
      });
    },
    /**
     * 置顶
     * @param id
     */
    doSetTop(id) {
      return setTop(id);
    },
    updateCount(row) {
      savePraise({ comment_id: row.id, praise_count: row.praise_count })
        .then((res) => {})
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
}
</style>
