<template>
  <LayoutFilter :onFilter="ok" :toggleCollapseEnable="false" :onReset="resetForm">
    <el-form :model="filterForm" :rules="rules" ref="commentFilterForm" label-width="70px" size="medium"
      class="filter-from">

      <div class="filter-item">
        <el-form-item label="评论内容：" prop="keyword">
          <el-input @keyup.enter.native="ok" v-model="form.keyword" placeholder="请输入内容"></el-input>
        </el-form-item>
        <el-form-item label="文章标题：" prop="keyword">
          <el-input @keyup.enter.native="ok" v-model="form.title" placeholder="请输入标题"></el-input>
        </el-form-item>
      </div>
      <div class="filter-item">
        <DatePeriodPicker label="时间：" start-prop="start_time" :start-time.sync="form.start_time"
          end-prop="end_time" :end-time.sync="form.end_time" />
      </div>
    </el-form>
  </LayoutFilter>
</template>

<script>
  import LayoutFilter from "@/base/layout/LayoutFilter";
  import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";

  export default {
  name: "ListFilter",
  components: { LayoutFilter, DatePeriodPicker },
  props: {
    filterForm: {
      type: Object,
    },
  },
  data() {
    return {
      rules: {},
      form: null,
    };
  },
  watch: {
    filterForm: {
      deep: true,
      handler(val) {
        this.form = val;
      },
    },
  },
  created() {
    this.form = this.filterForm;
  },
  methods: {
    //重置表单
    resetForm() {
      this.$refs["commentFilterForm"].resetFields();
      this.ok()
    },
    //筛选
    ok() {
      this.$emit("update", this.form);
    },
  },
};
</script>

<style scoped>
</style>
