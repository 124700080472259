/**
 * Vue mixin
 * 列表筛选相关
 */

export default {
  components: {},
  data() {
    return {};
  },
  methods: {
    // 筛选回调
    doFilter(e) {
      this.filterForm = { ...this.filterForm, ...e, page: 1 };
      this.getListData({ ...this.filterForm });
    },
    // 表格排序
    sortChange(column) {
      this.filterForm.is_desc = !column.order
        ? -1
        : column.order === "ascending"
        ? 0
        : 1;
      this.filterForm.order_by = column.prop;
      this.getListData(this.filterForm);
    },
  },
};
