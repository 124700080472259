/**
 * Vue mixin
 * 列表tabs，按需引入
 */

export default {
  data() {
    return {
      tabArr: [],
    }
  },
  methods: {
    // 切换tab栏
    handleTabClick(e) {
      this.pageData.tab = e.name;
      /* filters存放需要缓存筛选条件的页面 */
      if (this.filters[e.name]) {
        /* 还原当前tab先前的筛选条件（使用缓存） */
        this.filterForm = this.filters[e.name];
      } else {
        /* 只改tab，其他恢复默认值（不缓存） */
        this.initFilterForm(e.name);
      }
      this.getListData();
    },
  }
}
