/**
 * Vue mixin
 * 列表初始化相关
 */

export default {
  components: {},
  data() {
    return {
      loading: false,
      tbody: [],
    }
  },
  created() {
    if (this.$route.query.tab) {
      this.initFilterForm(this.$route.query.tab) // 初始化筛选条件
    }else{
      this.initFilterForm() // 初始化筛选条件
    }
    
    this.getListData() // 获取列表数据
  },
  methods: {
    getListData() {
      this.loading = true
      this.tbody = []
      this.pageData = {}
      console.log(this.filterForm);
      this.doGetListData(this.filterForm)
        .then((res) => {
          const { data } = res
          this.tbody = data.data
          this.pageData = data
          this.filters[data.tab] = { ...this.filterForm }
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
        })
    },
  },
}
