<template>
  <el-dialog class="reply-dialog dialog-vertical" title="回复评论" :visible="value" width="500px" @close="cancel">
    <el-input v-if="value" v-focus v-loading="loading"
              v-model="content" type="textarea" :rows="6"
              :maxlength="250" show-word-limit
    ></el-input>
    <template #footer>
      <el-button size="small" @click="cancel">取消</el-button>
      <el-button size="small" type="primary" @click="reply" :loading="loading">回复</el-button>
    </template>
  </el-dialog>
</template>

<script>
  import {reply} from "../../api/comment-list";

  export default {
  name: "ReplyComment",
  props: {
    value: {
      type: Boolean,
    },
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      content: "",
    };
  },
  methods: {
    reply() {
      this.doReply(() => {
        this.$emit("update");
        this.cancel();
      });
    },
    cancel() {
      this.$emit("input", false);
    },
    doReply(callback) {
      this.loading = true;
      reply({
        comment_id: this.id,
        content: this.content,
      })
        .then((res) => {
          this.loading = false;
          callback && callback();
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
</style>
