/**
 * Vue mixin
 * 通用的列表还原、删除等操作
 */

export default {
  data() {
    return {
      selectArr: [], // 勾选的数组id
    };
  },
  methods: {
    /* 接口请求 */
    /**
     * 置顶
     * @param {Number} id
     */
    setTop(id) {
      this.loading = true;
      this.doSetTop(id)
        .then((res) => {
          this.$message.success(res.msg);
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    /**
     * 审核
     * @param {Number} id
     * @param {Number} status   0:待审核，1：不通过， 2：通过
     * @param reason
     */
    updateStatus(id, status, reason = "") {
      this.loading = true;
      this.doUpdateStatus(this.getIdList(id), status, reason)
        .then((res) => {
          console.log(res);
          this.handleCommonSuccess(res.msg);
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    /**
     * 拉入/移出黑名单
     * @param {Number} id
     * @param {Number} status   0:取消黑名单 ，1：加入黑名单
     */
    updateBlacklist(id, status) {
      this.loading = true;
      this.doUpdateBlacklist(this.getIdList(id), status)
        .then((res) => {
          this.handleMoveSuccess(id, res.msg);
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    /**
     * 删除
     * @param {Number} id     必填，0为多选
     * @param {Boolean} bol   选填，是否永久删除
     */
    delete(id, bol = false) {
      this.loading = true;
      this[bol ? "doTrueDelete" : "doSoftDelete"](this.getIdList(id))
        .then((res) => {
          this.handleMoveSuccess(id, res.msg);
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    /**
     * 还原
     * @param {Number} id    要删除的id值，为0时表示多选
     */
    putBack(id = 0) {
      this.loading = true;
      this.doPutBack(this.getIdList(id))
        .then((res) => {
          this.handleMoveSuccess(id, res.msg);
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    /* 业务判断，to开头 */
    /**
     * 用户点击删除按钮
     * @param {Number} id    要删除的id值，为0时表示多选
     * @param {Boolean} bol  是否永久删除
     */
    toDelete(id = 0, bol = false) {
      if (!id && !this.selectArr.length) {
        return this.$message.info("请选择数据项");
      } else if (id || this.selectArr.length === 1) {
        this.showConfirm(
          {
            content: bol
              ? "确定删除这条数据？删除后不可恢复，请谨慎操作。"
              : "确定删除这条数据？",
            type: bol ? "error" : "warning",
          },
          () => {
            this.delete(id, bol);
          }
        );
      } else {
        this.showConfirm(
          {
            content: bol
              ? "确定删除这条数据？删除后不可恢复，请谨慎操作。"
              : "确定删除选中数据？",
            type: bol ? "error" : "warning",
          },
          () => {
            this.delete("", bol);
          }
        );
      }
    },
    /**
     * 用户点击还原按钮
     * @param {Number} id    要删除的id值，为0时表示多选
     */
    toPutBack(id = 0) {
      if (!id && !this.selectArr.length) {
        return this.$message.info("请选择数据项");
      } else if (id || this.selectArr.length === 1) {
        this.showConfirm(
          {
            content: "确定还原这条数据？",
          },
          () => {
            this.putBack(id);
          }
        );
      } else {
        this.showConfirm(
          {
            content: "确定还原选中数据？",
          },
          () => {
            this.putBack();
          }
        );
      }
    },
    /**
     * 用户点击审核按钮
     * @param {Number} id
     * @param {Number} status    0:待审核，1：不通过， 2：通过
     * @param reason
     */
    toUpdateStatus(id = 0, status, reason = "") {
      if (!id && !this.selectArr.length) {
        return this.$message.info("请选择数据项");
      } else if (id || this.selectArr.length === 1) {
        this.showConfirm(
          {
            content:
              status === 1 ? "确定不通过这条数据？" : "确定通过这条数据？",
          },
          () => {
            this.updateStatus(id, status, reason);
          }
        );
      } else {
        this.showConfirm(
          {
            content:
              status === 1 ? "确定不通过选中数据？" : "确定通过选中数据？",
          },
          () => {
            this.updateStatus(id, status, reason);
          }
        );
      }
    },
    /**
     * 用户点击拉黑或移出黑名单
     * @param id
     * @param {Number} status   0:取消黑名单 ，1：加入黑名单
     */
    toUpdateBlacklist(id, status) {
      if (!id && !this.selectArr.length) {
        return this.$message.info("请选择数据项");
      } else if (id || this.selectArr.length === 1) {
        this.showConfirm(
          {
            content:
              status === 1 ? "确定拉黑此用户？" : "确定将此用户移出黑名单？",
          },
          () => {
            this.updateBlacklist(id, status);
          }
        );
      } else {
        this.showConfirm(
          {
            content:
              status === 1
                ? "确定拉黑选中用户？"
                : "确定将选中用户移出黑名单？",
          },
          () => {
            this.updateBlacklist(id, status);
          }
        );
      }
    },
    /**
     * 修改列表相关状态（多选框（如置顶、显示、推荐等）、输入框（排序））
     * @param {Object} e      列表项数据
     * @param {Number} i      列表项索引
     * @param {String} key    列表项prop名
     **/
    changeStatus(e, i, key) {
      if (key !== "sort") {
        this.tbody[i][key] = e[key] ? 0 : 1;
      } else {
        this.tbody[i][key] = e[key];
      }
      const data = {};
      data.comment_id = e.id;
      data[key] = this.tbody[i][key];
      if (
        typeof data[key] === "number" &&
        data[key] % 1 !== 0 &&
        key === "sort" &&
        data[key] <= 0
      ) {
        this.$message.error("排序必须是正整数");
        return;
      }
      if (key === "is_top") {
        // 更新状态
        this.setTop(e.id);
      } else {
        // 更新排序状态
        // updateSort(data);
      }
    },
    /**
     * 操作确认框
     * @param {Object} obj            确认框配置参数
     * @param {Function} callback     点击确定回调函数
     */
    showConfirm(obj = {}, callback) {
      let _obj = obj || {};
      if (!_obj.content && callback) callback();
      this.$confirm(_obj.content, _obj.title || "提示", {
        confirmButtonText: _obj.confirmText || "确定",
        cancelButtonText: _obj.cancelText || "取消",
        type: _obj.type || "info",
      })
        .then(() => {
          callback && callback();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    /**
     * 列表多选框点击事件
     * @param {Array} selection
     */
    handleSelectionChange(selection) {
      if (!selection.length) {
        this.selectArr = [];
      } else {
        this.selectArr = [];
        // 筛选id
        selection.forEach((item) => {
          this.selectArr.push(item.id);
        });
      }
    },
    /**
     * 返回当前正在操作的 id 数组
     * @param {Number} id    要操作的id值，为0时表示多选
     * @returns {Array}      当前操作的id数组
     */
    getIdList(id) {
      if (!id) {
        return this.selectArr;
      }
      return [id];
    },
    /**
     * 需要移除数据的操作（还原、删除）成功后的统一处理
     * @param {Number} id    要操作的id值，为0时表示多选
     * @param {String} msg   成功提示，选填
     */
    handleMoveSuccess(id, msg) {
      if (msg) this.$message.success(msg);
      if (!id && this.selectArr.length > 1) {
        // 多选直接刷新页面数据算了
        this.getListData(this.filterForm);
      } else {
        // 单个操作，直接移除
        let index = this.tbody.findIndex(
          (item) => item.id === (id || this.selectArr[0].id)
        );
        this.tbody.splice(index, 1);
        this.loading = false;
      }
    },
    /**
     * 操作成功后一般处理
     * @param {String} msg    成功提示，选填
     */
    handleCommonSuccess(msg) {
      if (msg) {
        this.$message.success(msg);
      }

      this.getListData(this.filterForm);
      const myRef = this.$refs["checkDialog"];
      if (myRef) {
        myRef.close();
      }
    },
    /**
     * 填写不通过原因
     */
    openCheckDialog(id = 0) {
      if (!id && !this.selectArr.length) {
        return this.$message.info("请选择数据项");
      }
      const myRef = this.$refs["checkDialog"];
      if (myRef) myRef.open(id ? { id } : 0);
    },
  },
};
