<template>
  <el-dialog class="detail-dialog dialog-vertical" title="评论详情" width="500px" :visible="value" @close="close">
    <el-form size="medium" label-width="90px" v-loading="loading" @submit.native.prevent>
      <el-form-item label="评论状态：">{{detail.check_status ? '已审核':'未审核'}}</el-form-item>
      <el-form-item label="所属文章：">
        <el-link type="primary" @click="$emit('preview', detail.article_id)">{{detail.source_article}}
        </el-link>
      </el-form-item>
      <el-form-item label="评论内容：">{{detail.content}}</el-form-item>
      <el-form-item label="回复数：">{{detail.replies_count}}</el-form-item>
      <el-form-item label="用户点赞：">{{detail.likes_count}}</el-form-item>
      <el-form-item label="运营点赞：" style="margin: 9px 0">
        <el-input type="number" :min="0" style="width: 100px" v-model="detail.praise_count"
                  @keyup.enter.native="saveVirtual"></el-input>
      </el-form-item>
      <el-form-item label="举报数：">
        {{detail.complaints_count}}
        <!--  <el-button type="text" @click="toReportDetail">&gt;&gt;查看详情&lt;&lt;</el-button>-->
        <span class="view-details" @mouseover="enter()" @mouseout="leave()"> >>>查看详情<<< </span>
        <transition name="slide-fade">
          <div class="reports-show" v-if="isShow">
            <ul>
              <li>淫秽色情 {{report.porn}}</li>
              <li>政治反动 {{report.reactionary}}</li>
              <li>营销广告 {{report.advertising}}</li>
              <li>恶意攻击谩骂 {{report.Dirty_words}}</li>
              <li>违法有害信息 {{report.harmful}}</li>
            </ul>
          </div>
        </transition>
      </el-form-item>
      <el-form-item label="评论时间：">{{detail.create_time}}</el-form-item>
      <el-form-item label="评论者：">{{detail.user_name}}</el-form-item>
      <el-form-item label="评论IP：">{{detail.user_ip}}</el-form-item>
      <el-divider v-if="detail.ancestor"></el-divider>
      <div v-if="detail.ancestor">
        <p class="parent-comment">上级评论</p>
        <el-form-item label="评论内容：">{{detail.ancestor.content}}</el-form-item>
        <el-form-item label="评论时间：">{{detail.ancestor.create_time}}</el-form-item>
        <el-form-item label="评论者：">{{detail.ancestor.user_name}}</el-form-item>
        <el-form-item label="评论IP：">{{detail.ancestor.user_ip}}</el-form-item>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
  import {getCommentDetails, reportDetails, savePraise,} from "../../api/comment-list";

  export default {
    name: "DetailDialog",
    props: {
      value: {
        type: Boolean,
      },
      id: {
        type: Number,
      },
    },
    data() {
      return {
        loading: false,
        detail: {},
        report: {},
        isShow: false,
        reportDialog: {
          show: false,
        },
      };
    },
    watch: {
      value(val) {
        if (val) {
          this.getDetail();
        }
      },
    },
    methods: {
      saveVirtual() {
        this.loading = true;
        savePraise({
          comment_id: this.detail.id,
          praise_count: this.detail.praise_count,
        })
          .then((res) => {
            this.$message.success(res.msg);
            this.$emit('update')
          })
          .catch((err) => {
          }).finally(() => {
          this.loading = false;
        })
      },
      enter() {
        this.isShow = true;
      },
      leave() {
        this.isShow = false;
      },
      close() {
        this.$emit("input", false);
      },
      getDetail() {
        this.loading = true;
        getCommentDetails({
          comment_id: this.id,
        })
          .then((res) => {
            this.detail = res.data;
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
          });

        reportDetails({
          comment_id: this.id,
        })
          .then((res) => {
            this.report = res.data;
          })
          .catch((err) => {
          });
      },
      toReportDetail() {
        this.reportDialog.show = true;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .el-form .el-link {
    line-height: 1.5;
  }

  .reports-show {
    position: absolute;
    top: 10px;
    left: 110px;
    width: 280px;
    height: 70px;
    padding: 5px 10px;
    background: rgba(255, 249, 188, 1);
    border-radius: 4px;
    border: 1px solid rgba(239, 229, 126, 1);
    font-size: 14px;
    color: rgba(139, 87, 42, 1);
  }

  ul,
  li {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .reports-show ul li {
    float: left;
    margin-right: 15px;
  }

  .view-details {
    margin-left: 10px;
    font-size: 16px;
    color: rgba(70, 128, 255, 1);
    line-height: 22px;
    cursor: pointer;
  }

  .parent-comment {
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
  }

  .el-form-item {
    margin-bottom: 0;
  }
</style>
